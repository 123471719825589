import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class CrmService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getCrmDashboardTotals(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getCrmDashboardTotals, ...args)
    }
}

function useCrmService() {
  const crmService = new CrmService()

  return {
    crmService,
  }
}

const { crmService } = useCrmService()
export default crmService
