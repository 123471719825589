<template>
  <div style="padding: 20px;">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">
              <b-card
                no-body
                style="height: 420px;"
              >
                <b-card-header class="align-items-baseline">
                  <b-card-title>Campaigns</b-card-title>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="AllCampaign()"
                  >
                    Show All
                  </b-button>
                </b-card-header>

                <b-card-body>
                  <vue-apex-charts
                    ref="CampaignChart"
                    width="100%"
                    :options="statisticsCampaign"
                    :series="statisticsCampaign.series"
                  />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="8">
              <b-card
                no-body
              >
                <b-card-header class="align-items-baseline">
                  <div>
                    <b-card-title>Health Plans</b-card-title>
                  </div>
                </b-card-header>

                <b-card-body>
                  <vue-apex-charts
                    ref="hpChart"
                    width="100%"
                    height="320"
                    :options="statisticsHP"
                    :series="statisticsHP.series"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

        </b-col>
        <b-col md="3">
          <b-card
            no-body
          >
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title>Status</b-card-title>
                <b-card-text class="text-muted font-small-2">
                  <span>Totals of this Month</span>
                </b-card-text>
              </div>
            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                ref="StatusChart"
                width="100%"
                height="300"
                :options="statisticStatus"
                :series="statisticStatus.series"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col md="9">
          <b-row>
            <b-col md="7">
              <b-card
                no-body
              >
                <b-card-header class="align-items-baseline">
                  <div>
                    <b-card-title>Representatives</b-card-title>
                    <b-card-text class="text-muted font-small-2">
                      <span>has more Leads Completed this Month.</span>
                    </b-card-text>
                  </div>
                </b-card-header>

                <b-card-body>
                  <vue-apex-charts
                    ref="RepChart"
                    width="100%"
                    height="319"
                    :options="statisticsRep"
                    :series="statisticsRep.series"
                  />
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="5">
              <b-card
                no-body
              >
                <b-card-header class="align-items-baseline">
                  <div>
                    <b-card-title>Sites</b-card-title>
                  </div>
                </b-card-header>

                <b-card-body>
                  <vue-apex-charts
                    ref="SitesChart"
                    width="100%"
                    height="340"
                    :options="statisticsSites"
                    :series="statisticsSites.series"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3">
          <b-card
            no-body
          >
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title>Reasons</b-card-title>
                <b-card-text class="text-muted font-small-2">
                  <span>Totals of this Month.</span>
                </b-card-text>
              </div>
            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                ref="ReasonChart"
                width="100%"
                height="300"
                :options="statisitcReasons"
                :series="statisitcReasons.series"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BButton, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import crmService from '@core/services/crm/crmService'

export default {
  components: {
    BOverlay,
    VueApexCharts,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BButton,
  },
  data() {
    return {
      show: true,
      statisticsCampaign: {
        chart: {
          height: 100,
          type: 'pie',
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          offsetY: 5,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.getCampign(config)
            },
          },
        },
        tooltip: {
          enabled: true,
          /* x: {
            show: true,
            formatter(value) {
              return value.name
            },
          },
          y: {
            show: true,
            formatter(val) {
              return val
            },
          },
          yaxis: {
            labels: {
              formatter: value => `${value.name}%`,
            },
          },
          xaxis: {
            labels: {
              formatter: value => `${value.name}%`,
            },
          }, */
          custom(opts) {
            console.log(opts)
            const desc = opts.ctx.w.config.labels[opts.seriesIndex].name
            const value = opts.ctx.w.config.series[opts.seriesIndex]
            const color = opts.ctx.theme.colors[opts.seriesIndex]
            return `<div class="arrow_box" style="background-color: ${color};">
                      <span class="font">${desc} :   ${value}</span>
                    </div>`
          },
        },
        labels: {
          formatter(val) {
            return `${val.id}`
          },
        },
        legend: {
          position: 'bottom',
          formatter(val) {
            return `${val.name}`
          },
        },
      },
      statisticsHP: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
      statisticStatus: {
        chart: {
          height: 350,
          type: 'radar',
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 80,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff'],
              },
            },
          },
        },
        markers: {
          size: 4,
          colors: ['#fff'],
          strokeWidth: 2,
        },
      },
      statisticsRep: {
        series: [],
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          distributed: true,
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
      },
      statisticsSites: {
        series: [],
        chart: {
          type: 'treemap',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          treemap: {
            enableShades: false,
          },
        },
      },
      statisitcReasons: {
        chart: {
          type: 'radar',
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 80,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff'],
              },
            },
          },
        },
        markers: {
          size: 4,
          colors: ['#fff'],
          strokeWidth: 2,
        },
        xaxis: {
          categories: ['Converted to Member', 'Disconnected Phone', 'Dont Answer', 'No Longer Interested', 'Unavaailable', 'Wrong Number'],
        },
      },
      idCampaign: '',
      loadingtreemap: false,
    }
  },
  mounted() {
    this.getDataGraphics()
  },
  methods: {
    AllCampaign() {
      this.idCampaign = ''
      this.getDataGraphics()
    },
    getCampign(data) {
      const label = data.w.config.labels[data.dataPointIndex]
      const empty = data.selectedDataPoints
      if (empty[0].length > 0) {
        this.idCampaign = `${label.id}`
        this.getDataGraphics()
      } else {
        this.idCampaign = ''
        this.getDataGraphics()
      }
    },
    getDataGraphics() {
      this.show = true
      this.clearData()
      crmService.getCrmDashboardTotals({
        campaign: this.idCampaign,
        reasonActivityChange: '',
        patientStatusCampaign: '',
        city: '',
        user: '',
        clinic: '',
        priorPlan: '',
        search: '',
      }).then(response => {
        const {
          campaignTotal, healthPlanTotals, clinicTotals, reasonTotals, repTotals, statusTotals,
        } = response.data.data
        console.log(campaignTotal)
        this.$refs.CampaignChart.updateOptions({
          labels: campaignTotal.labels,
          series: campaignTotal.series,
        })
        this.$refs.hpChart.updateOptions({
          xaxis: {
            categories: healthPlanTotals.categories,
          },
          series: healthPlanTotals.series,
        })
        this.$refs.SitesChart.updateOptions({
          series: clinicTotals,
        })
        this.$refs.StatusChart.updateOptions({
          series: statusTotals.series,
          xaxis: {
            categories: statusTotals.categories,
          },
        })
        this.$refs.RepChart.updateOptions({
          series: repTotals.series,
          xaxis: {
            categories: repTotals.categories,
            axisBorder: {
              show: false,
            },
          },
        })
        this.$refs.ReasonChart.updateOptions({
          series: reasonTotals.series,
          xaxis: {
            categories: reasonTotals.categories,
          },
        })
        this.show = false
        window.dispatchEvent(new Event('resize'))
      })
    },
    clearData() {
      this.$refs.SitesChart.updateOptions({
        series: [],
      })
      this.$refs.CampaignChart.updateOptions({
        series: [],
      })
      this.$refs.hpChart.updateOptions({
        series: [],
      })
      this.$refs.StatusChart.updateOptions({
        series: [],
      })
      this.$refs.RepChart.updateOptions({
        series: [],
      })
      this.$refs.ReasonChart.updateOptions({
        series: [],
      })
    },
    convertData(array) {
      const Values = []
      const Names = []
      array.forEach(element => {
        Values.push(element.Total)
        Names.push(element.Name)
      })
      return { Values, Names }
    },
  },
}
</script>

<style>
.arrow_box{
  color: white;
}
.font{
  font-size: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>
